import { takeLatest } from 'redux-saga/effects';
import {
	getLangSaga,
	getLiteralsSaga,
	registerSaga,
	loginSaga,
	confirmTokenSaga,
	forgotPassword,
	setNewPassword,
	changeUserPasswordSaga,
	logoutSaga,
	changeUserEmailSaga,
} from './authenticationSaga';
import {
	userAccountSaga,
	updateUserAccountSaga,
	updateUserAccountLanguageSaga,
	getInformationSaga,
	createLicenseSaga,
	getAccountLicenseCountSaga,
	getKeyPersonSaga,
} from './userSaga';
import {
	getAllGroup,
	createGroup,
	deleteGroup,
	updateGroup,
	addEmployeesToGroup,
	getAllEmployee,
	getAllRemovedEmployee,
	getAllReferenceProfiles,
	createEmployer,
	createReferenceProfile,
	deleteEmployer,
	removeEmployeesFinally,
	deleteReferenceProfile,
	restoreEmployer,
	updateEmployer,
	downloadStructure,
	createTestEmployeeSaga,
	bindKeySaga,
	unbindKeySaga,
} from './structureSaga';
import { getLicenseCountSaga, getLicenseHistorySaga, getLicenseUsedSaga } from './licenseSaga';

import {
	getAllTest,
	createReportGroup,
	shareReport,
	unShareReport,
	getAllReportsGroup,
	deleteReportGroup,
	updateReportGroup,
	deleteReportIndividual,
} from './reportSaga';

import {
	completePaypalOrderSaga,
	cancelPaypalOrderSaga,
	createPaypalOrderSaga,
	completeStripeOrderSaga,
	cancelStripeOrderSaga,
	createStripeOrderSaga,
	getStripeConfigSaga,
	completePaypalOrderWithoutAuthSaga,
	cancelPaypalOrderWithoutAuthSaga,
	createPaypalOrderWithoutAuthSaga,
	completeStripeOrderWithoutAuthSaga,
	cancelStripeOrderWithoutAuthSaga,
	createStripeOrderWithoutAuthSaga,
	checkPromoCodeSaga,
} from './paymentSaga';

import {
	getAllEmployeeSkillsSaga,
	getSkillsFilterSaga,
	createSkillsFilterSaga,
	updateSkillsFilterSaga,
	deleteSkillsFilterSaga,
	getAllSkillsSaga,
	findSkillsFilterSaga,
	findIdSkillsFilterSaga,
} from './skillsSaga';

import * as types from '../store/constants';

export function* watchUserAuthentication() {
	yield takeLatest(types.GET_LANGUAGES, getLangSaga);
	yield takeLatest(types.GET_LITERALS, getLiteralsSaga);
	yield takeLatest(types.REGISTER_USER, registerSaga);
	yield takeLatest(types.LOGIN_USER, loginSaga);
	yield takeLatest(types.LOGOUT_USER, logoutSaga);
	yield takeLatest(types.CONFIRM_TOKEN, confirmTokenSaga);
	yield takeLatest(types.FORGOT_PASSWORD, forgotPassword);
	yield takeLatest(types.SET_NEW_PASSWORD, setNewPassword);
	yield takeLatest(types.CHANGE_USER_PASSWORD, changeUserPasswordSaga);
	yield takeLatest(types.CHANGE_USER_EMAIL, changeUserEmailSaga);
}

export function* watchUserAccount() {
	yield takeLatest(types.GET_USER_ACCOUNT, userAccountSaga);
	yield takeLatest(types.UPDATE_USER_ACCOUNT, updateUserAccountSaga);
	yield takeLatest(types.UPDATE_USER_ACCOUNT_LANGUAGE, updateUserAccountLanguageSaga);
	yield takeLatest(types.GET_EMPLOYEE_COUNT, getInformationSaga);
	yield takeLatest(types.CREATE_LICENSE, createLicenseSaga);
	yield takeLatest(types.GET_ACCOUNT_LICENSE_COUNT, getAccountLicenseCountSaga);
	yield takeLatest(types.GET_KEY_PERSON, getKeyPersonSaga);
}

export function* watchStructure() {
	yield takeLatest(types.GET_ALL_GROUP, getAllGroup);
	yield takeLatest(types.CREATE_GROUP, createGroup);
	yield takeLatest(types.DELETE_GROUP, deleteGroup);
	yield takeLatest(types.UPDATE_GROUP, updateGroup);
	yield takeLatest(types.ADD_EMPLOYEE_TO_GROUP, addEmployeesToGroup);
	yield takeLatest(types.GET_ALL_EMPLOYEE, getAllEmployee);
	yield takeLatest(types.GET_ALL_REMOVED_EMPLOYEE, getAllRemovedEmployee);
	yield takeLatest(types.GET_ALL_REFERENCE_PROFILE, getAllReferenceProfiles);
	yield takeLatest(types.CREATE_EMPLOYEE, createEmployer);
	yield takeLatest(types.CREATE_REFERENCE_PROFLE, createReferenceProfile);
	yield takeLatest(types.DELETE_EMPLOYEE, deleteEmployer);
	yield takeLatest(types.REMOVE_EMPLOYEES_FINALLY, removeEmployeesFinally);
	yield takeLatest(types.DELETE_REFERENCE_PROFILE, deleteReferenceProfile);
	yield takeLatest(types.RESTORE_EMPLOYEE, restoreEmployer);
	yield takeLatest(types.UPDATE_EMPLOYEE, updateEmployer);
	yield takeLatest(types.DOWNLOAD_STRUCTURE, downloadStructure);
	yield takeLatest(types.CREATE_TEST_EMPLOYEE, createTestEmployeeSaga);
	yield takeLatest(types.BIND_KEY, bindKeySaga);
	yield takeLatest(types.UNBIND_KEY, unbindKeySaga);
}

export function* watchLicense() {
	yield takeLatest(types.GET_LICENSE_COUNT, getLicenseCountSaga);
	yield takeLatest(types.GET_LICENSE_HISTORY, getLicenseHistorySaga);
	yield takeLatest(types.GET_LICENSE_USED, getLicenseUsedSaga); // need delete
}

export function* watchReport() {
	yield takeLatest(types.CREATE_REPORT_GROUP, createReportGroup);
	yield takeLatest(types.SHARE_REPORT, shareReport);
	yield takeLatest(types.UNSHARE_REPORT, unShareReport);
	yield takeLatest(types.GET_ALL_TEST, getAllTest);
	yield takeLatest(types.GET_ALL_REPORTS_GROUP, getAllReportsGroup);
	yield takeLatest(types.DELETE_REPORT_GROUP, deleteReportGroup);
	yield takeLatest(types.UPDATE_REPORT_GROUP, updateReportGroup);
	yield takeLatest(types.DELETE_REPORT_INDIVIDUAL, deleteReportIndividual);
}

export function* watchPayment() {
	yield takeLatest(types.COMPLETE_PAYPAL_PAYMENT, completePaypalOrderSaga);
	yield takeLatest(types.CANCEL_PAYPAL_PAYMENT, cancelPaypalOrderSaga);
	yield takeLatest(types.CREATE_PAYPAL_PAYMENT, createPaypalOrderSaga);
	yield takeLatest(types.COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH, completePaypalOrderWithoutAuthSaga);
	yield takeLatest(types.CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH, cancelPaypalOrderWithoutAuthSaga);
	yield takeLatest(types.CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH, createPaypalOrderWithoutAuthSaga);

	yield takeLatest(types.COMPLETE_STRIPE_PAYMENT, completeStripeOrderSaga);
	yield takeLatest(types.CANCEL_STRIPE_PAYMENT, cancelStripeOrderSaga);
	yield takeLatest(types.CREATE_STRIPE_PAYMENT, createStripeOrderSaga);
	yield takeLatest(types.COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH, completeStripeOrderWithoutAuthSaga);
	yield takeLatest(types.CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH, cancelStripeOrderWithoutAuthSaga);
	yield takeLatest(types.CREATE_STRIPE_PAYMENT_WITHOUT_AUTH, createStripeOrderWithoutAuthSaga);

	yield takeLatest(types.GET_STRIPE_CONFIG, getStripeConfigSaga);

	yield takeLatest(types.PROMO_CODE_PAYMENT, checkPromoCodeSaga);
}

export function* watchSkills() {
	yield takeLatest(types.GET_ALL_EMPLOYEE_SKILLS, getAllEmployeeSkillsSaga);
	yield takeLatest(types.GET_SKILLS_FILTER, getSkillsFilterSaga);
	yield takeLatest(types.CREATE_SKILLS_FILTER, createSkillsFilterSaga);
	yield takeLatest(types.UPDATE_SKILLS_FILTER, updateSkillsFilterSaga);
	yield takeLatest(types.DELETE_SKILLS_FILTER, deleteSkillsFilterSaga);
	yield takeLatest(types.GET_ALL_SKILLS, getAllSkillsSaga);
	yield takeLatest(types.FIND_SKILLS_FILTER, findSkillsFilterSaga);
	yield takeLatest(types.FIND_ID_SKILLS_FILTER, findIdSkillsFilterSaga);
}
