import endpoints from '../configs/endpoints';

export const API_ROOT = endpoints.API_ROOT.path;
export const ROOT = endpoints.ROOT.path;
export const API_SERVICE_ROOT = endpoints.API_SERVICE_ROOT.path;
// https://gnt-service-pdf.app.se7ensky.com

export const AUTH_TOKEN = 'Authorization';

export const REQUEST = '_REQUEST';
export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';
export const RANGE = '_RANGE';
export const RESET_ERROR = 'RESET_ERROR';

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LITERALS = 'GET_LITERALS';
export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CONFIRM_TOKEN = 'CONFIRM_TOKEN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const RESET_USER = 'RESET_USER';

export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_SWITCH = 'MODAL_SWITCH';
export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT_LANGUAGE = 'UPDATE_USER_ACCOUNT_LANGUAGE';
export const UPGRADE_USER_ACCOUNT = 'UPGRADE_USER_ACCOUNT';
export const GET_EMPLOYEE_COUNT = 'GET_EMPLOYEE_COUNT';
export const GET_KIND_ACTIVITY = 'GET_KIND_ACTIVITY';
export const GET_ALL_GROUP = 'GET_ALL_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_EMPLOYEE_TO_GROUP = 'ADD_EMPLOYEE_TO_GROUP';
export const DOWNLOAD_STRUCTURE = 'DOWNLOAD_STRUCTURE';
export const CREATE_TEST_EMPLOYEE = 'CREATE_TEST_EMPLOYEE';
export const BIND_KEY = 'BIND_KEY';
export const UNBIND_KEY = 'UNBIND_KEY';
export const CHANGE_SORT_TYPE = 'CHANGE_SORT_TYPE';
export const GET_ALL_TEST = 'GET_ALL_TEST';
export const CREATE_REPORT_GROUP = 'CREATE_REPORT_GROUP';
export const SHARE_REPORT = 'SHARE_REPORT';
export const REMOVE_EMPLOYEES_FINALLY = 'REMOVE_EMPLOYEES_FINALLY';
export const DELETE_REFERENCE_PROFILE = 'DELETE_REFERENCE_PROFILE';
export const UNSHARE_REPORT = 'UNSHARE_REPORT';
export const GET_ALL_REPORTS_GROUP = 'GET_ALL_REPORTS_GROUP';
export const DELETE_REPORT_GROUP = 'DELETE_REPORT_GROUP';
export const DELETE_REPORT_INDIVIDUAL = 'DELETE_REPORT_INDIVIDUAL';
export const UPDATE_REPORT_GROUP = 'UPDATE_REPORT_GROUP';

export const GET_ALL_EMPLOYEE = 'GET_ALL_EMPLOYEE';
export const GET_ALL_EMPLOYEE_SKILLS = 'GET_ALL_EMPLOYEE_SKILLS';
export const GET_SKILLS_FILTER = 'GET_SKILLS_FILTER';
export const CREATE_SKILLS_FILTER = 'CREATE_SKILLS_FILTER';
export const UPDATE_SKILLS_FILTER = 'UPDATE_SKILLS_FILTER';
export const DELETE_SKILLS_FILTER = 'DELETE_SKILLS_FILTER';
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const FIND_SKILLS_FILTER = 'FIND_SKILLS_FILTER';
export const FIND_ID_SKILLS_FILTER = 'FIND_ID_SKILLS_FILTER';
export const GET_ALL_REMOVED_EMPLOYEE = 'GET_ALL_REMOVED_EMPLOYEE';
export const GET_ALL_REFERENCE_PROFILE = 'GET_ALL_REFERENCE_PROFILE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_REFERENCE_PROFLE = 'CREATE_REFERENCE_PROFLE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const RESTORE_EMPLOYEE = 'RESTORE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const CREATE_LICENSE = 'CREATE_LICENSE';
export const GET_KEY_PERSON = 'GET_KEY_PERSON';
export const RESET_STRUCTURE = 'RESET_STRUCTURE';

export const GET_LICENSE_COUNT = 'GET_LICENSE_COUNT';
export const GET_LICENSE_HISTORY = 'GET_LICENSE_HISTORY';
export const GET_LICENSE_USED = 'GET_LICENSE_USED';

export const GET_ACCOUNT_LICENSE_COUNT = 'GET_ACCOUNT_LICENSE_COUNT';

export const LOADING = 'loading';
export const LOADING_MAIL = 'loading-mail';
export const ERROR_LOADING = 'error-loading';
export const ERROR_LOADING_MAIL = 'error-loading-mail';

export const ACCOUNT_MODAL_BUY = 'modalBuy';
export const PUBLIC_MODAL_AUTH = 'modalAuth';
export const CREATE_MODAL_EMPLOYEE = 'modalCreateEmployer';
export const PUBLIC_MODAL_AUTH_LOGIN = 'auth-login';
export const PUBLIC_MODAL_AUTH_REGISTER = 'auth-register';
export const PUBLIC_MODAL_AUTH_REGISTER_SUCCESS = 'auth-register-success';
export const PUBLIC_MODAL_AUTH_FORGOT = 'auth-forgot';
export const PUBLIC_MODAL_AUTH_FORGOT_STEP_TWO = 'auth-forgot-step-two';
export const PUBLIC_MODAL_PASSWORD_RESET = 'auth-password-reset';
export const PUBLIC_MODAL_PASSWORD_RESET_STEP_TWO = 'auth-password-reset-step-two';
export const ACCOUNT_MODAL_PASSWORD_CHANGE = 'modalPasswordChange';
export const ACCOUNT_MODAL_EMAIL_CHANGE = 'modalEmailChange';
export const ACCOUNT_MODAL_CREATE_GROUP = 'modalCreateGroup';
export const MODAL_GROUP_DELETE = 'modalGroupDelete';
export const MODAL_GROUP_REPORT = 'modalGroupReport';
export const MODAL_SHARE = 'modalShare';
export const MODAL_SHARE_SETTINGS = 'modalShareSettings';
export const MODAL_SUCCESS = 'modalSuccess';
export const MODAL_EMPLOYEE_DELETE = 'modalDelete';
export const MODAL_REMOVE_EMPLOYEES_FINALLY = 'modalRemoveFinally';
export const MODAL_REFERENCE_PROFILE_DELETE = 'modalDeleteReferenceProfile';
export const MODAL_REFERENCE_PROFILE = 'modalReferenceProfile';
export const MODAL_EMPLOYEE_RESTORE = 'modalRestore';
export const MODAL_ADD_EMPLOYEE_TO_GROUP = 'modalAddEmployeeToGroup';
export const MODAL_CREATE_TEST_EMPLOYEE = 'modalCreateTestEmployee';
export const MODAL_BIND_KEY = 'modalBindKey';
export const MODAL_UNBIND_KEY = 'modalUnbindKey';
export const MODAL_DELETE_REPORT_GROUP = 'modalDeleteReportGroup';
export const MODAL_SEARCH_SKILLS = 'modalSearchSkills';
export const MODAL_DELETE_INDIVIDUAL_REPORT = 'modalDeleteIndividualReport';

export const MODAL_STEP_ONE = 'modal-step-one';
export const MODAL_STEP_TWO = 'modal-step-two';

export const ROUTE_TAB_EMPLOYEES = 'employees';
export const ROUTE_TAB_REMOVER_EMPLOYEES = 'employees-removed';
export const ROUTE_TAB_GROUPS = 'groups';

export const REGEX_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const REGEX_PASSWORD = /^(?=.*[0-9]).{8,30}$/;

export const PRIVATE_PERSON = 'PRIVATE_PERSON';
export const ORGANIZATION = 'ORGANIZATION';

export const MALE = 'male';
export const FEMALE = 'female';

// payment system PayPal
export const CREATE_PAYPAL_PAYMENT = 'CREATE_PAYPAL_PAYMENT';
export const CANCEL_PAYPAL_PAYMENT = 'CANCEL_PAYPAL_PAYMENT';
export const COMPLETE_PAYPAL_PAYMENT = 'COMPLETE_PAYPAL_PAYMENT';

// payment system PayPal without auth
export const CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH = 'CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH';
export const CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH = 'CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH';
export const COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH = 'COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH';

// payment system Stripe
export const CREATE_STRIPE_PAYMENT = 'CREATE_STRIPE_PAYMENT';
export const CANCEL_STRIPE_PAYMENT = 'CANCEL_STRIPE_PAYMENT';
export const COMPLETE_STRIPE_PAYMENT = 'COMPLETE_STRIPE_PAYMENT';

// payment system Stripe without auth
export const CREATE_STRIPE_PAYMENT_WITHOUT_AUTH = 'CREATE_STRIPE_PAYMENT_WITHOUT_AUTH';
export const CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH = 'CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH';
export const COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH = 'COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH';
export const GET_STRIPE_CONFIG = 'GET_STRIPE_CONFIG';

export const RUB = 'RUB';
export const EURO = 'EURO';
export const DOLLAR = 'DOLLAR';
export const UAH = 'UAH';

export const PROMO_CODE_PAYMENT = 'PROMO_CODE_PAYMENT';

export const XLSX = {
	type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	expansion: 'xlsx',
};

export const XLS = {
	type: 'application/vnd.ms-excel',
	expansion: 'xls',
};

// window media breakpoints
export const MIN_DESKTOP_WIDTH = 1000;
export const MIN_TABLET_WIDTH = 768;

export const KEY_ALREADY_IN_ORGANIZATION = 'KEY_ALREADY_IN_ORGANIZATION';
export const KEY_NOT_FOUND = 'KEY_NOT_FOUND';
